/**
 *
 * @description Get item storage
 * @param key
 *
 */
export const getItem = key => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key)) ?? undefined
  }

  return undefined
}

/**
 *
 * @description Set item storage
 * @param key
 * @param param
 *
 */
export const setItem = async (key, param) => {
  await localStorage.setItem(key, JSON.stringify(param));
}

/**
 *
 * @description Delete item storage
 * @param key
 *
 */
export const deleteItem = async key => {
  await localStorage.removeItem(key)
}

/**
 *
 * @description Set clear storage
 *
 */
export const setClear = async () => {
  await localStorage.clear()
}
